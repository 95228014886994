import api from "@/base/utils/request";

// 获取预备会员任务配置
export const taskData = data => {
  return api({
    url: "/admin/cyc/organization/task",
    method: "post",
    data
  });
};

// 是否开启任务
export const statusTask = data => {
  return api({
    url: "/admin/cyc/organization/statusTask",
    method: "post",
    data
  });
};

// 编辑规则详情
export const detailTask = data => {
  return api({
    url: "/admin/cyc/organization/detailTask",
    method: "post",
    data
  });
};

// 保存任务配置
export const saveTask = data => {
  return api({
    url: "/admin/cyc/organization/saveTask",
    method: "post",
    data
  });
};

// 获取活动列表
export const ActivityList = data => {
  return api({
    url: "/admin/cyc_activity/activity/searchActivity",
    method: "post",
    data
  });
};