<template>
  <div class="confirm-content">
    <el-button class="add-member" @click="addActivity">添加活动</el-button>
    <el-table v-loading="loading" :data="list" class="thead-light" stripe style="width: 100%"
      @sort-change="sortChange">
      <!-- 操作 -->
      <el-table-column label="操作" :width="80" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="DeleteRow(scope.$index)">删除</el-button>
        </template>
      </el-table-column>
      <template v-for="(th, index) in thead">
        <el-table-column :prop="th.prop" :min-width="th.minWidth || ''" :sortable="th.sortable" :key="index">
          <template slot="header" slot-scope="scope">
            <span v-if="th.prop === 'position'"><span style="color: red">*</span>{{th.label}}</span>
            <span v-else>{{th.label}}</span>
          </template>
          <template slot-scope="scope">
            <!-- 普通 -->
            <span>{{ scope.row[th.prop] | placeholder }}</span>
          </template>
        </el-table-column>
      </template>
    </el-table>
    <ActivitySelector v-model="openActivitySelector" :selected="list" @listChange="handleChange" />
  </div>
</template>

<script>
import ActivitySelector from "./ActivitySelector";
export default {
  components: { ActivitySelector },
  model: {
    prop: "activityList",
    event: "changeList",
  },
  props: {
    activityList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      thead: [
        { label: "ID", prop: "id", minWidth: 120 },
        { label: "活动名称", prop: "name", minWidth: 200 },
        {
          label: "活动时间",
          prop: "activity_time_text",
          minWidth: 250,
          sortable: true,
        },
      ],
      filterForm: {
        // page_size: 15,
      },
      // pageData: {},
      openActivitySelector: false,
    };
  },
  computed: {
    list: {
      get() {
        return this.activityList;
      },
      set(val) {
        this.$emit("changeList", val);
      },
    },
  },
  methods: {
    // 点击添加
    addActivity() {
      this.openActivitySelector = true;
    },
    // 点击移除
    DeleteRow(index) {
      this.list.splice(index, 1);
    },
    // 选择活动操作
    handleChange(val) {
      this.list.push(...val);
      // this.list = [...this.list, ...val];
    },
    // 序号计算
    indexMethod(index) {
      return index + 1;
    },
    //表格排序
    sortChange(column) {
      this.filterForm.is_desc = !column.order
        ? -1
        : column.order === "ascending"
        ? 0
        : 1;
      this.filterForm.order_by = column.prop;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-member {
  margin: 0 10px 20px;
}
</style>