<template>
  <div v-loading="loading">
    <el-form v-if="formData" size="medium" :model="formData" label-position="right" label-width="100px"
      class="medium-form">
      <div class="modular" style="margin-top: 20px">
        <p class="modular-title">规则设置</p>
        <div class="modular-content">
          <el-form-item label="活动参加规则：" prop="join_status">
            <div>
              <el-radio v-model="formData.join_status" :label="0">以下活动分别参加一次</el-radio>
            </div>
            <div>
              <el-radio v-model="formData.join_status" :label="1">选择一个活动参加一次</el-radio>
            </div>
          </el-form-item>
        </div>
      </div>
      <div class="modular">
        <p class="modular-title">活动列表</p>
        <div class="modular-content">
          <activity-list v-if="formData.activity" :activityList="formData.activity" />
        </div>
      </div>
    </el-form>
    <FixedActionBar>
      <el-button type="primary" @click="submit" :loading="saveLoading">保存</el-button>
      <el-button @click="cancle">取消</el-button>
    </FixedActionBar>
  </div>
</template>

<script>
import FixedActionBar from "@/base/layout/FixedActionBar";
import ActivityList from "../../components/OrgFrame/ActivityList";
import { detailTask, saveTask } from "../../api/org-frame/task-config";
export default {
  components: { FixedActionBar, ActivityList },
  data() {
    return {
      loading: false,
      saveLoading: false,
      id: this.$route.params.id,
      formData: {
        join_status: 0,
        id: [],
      },
    };
  },
  created() {
    this.getRuleDetail();
  },
  methods: {
    //获取规则设置详情
    getRuleDetail() {
      this.loading = true;
      detailTask({ id: this.id })
        .then((res) => {
          this.formData = res.data;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    // 保存
    submit() {
      this.saveLoading = true;
      const ids = [];
      this.formData.activity.forEach((el) => {
        ids.push(el.id);
      });
      const data = {};
      data.id = this.id;
      data.config = {
        id: ids,
        join_status: this.formData.join_status,
      };
      saveTask(data)
        .then((res) => {
          this.$message.success(res.msg);
          this.$router.push({
            name: "TaskConfig",
            params: { id: this.$route.params.previousId },
          });
          this.saveLoading = false;
        })
        .catch(() => {
          this.saveLoading = false;
        });
    },
    // 取消
    cancle() {
      this.$router.push({
        name: "TaskConfig",
        params: { id: this.$route.params.previousId },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modular + .modular {
  margin-top: 85px;
}
.modular-content {
  margin-left: 8px;
  .el-form-item {
    margin-bottom: 2px;
    ::v-deep.el-form-item__label {
      display: inline-block;
      margin: auto 0;
    }
    ::v-deep.el-form-item__content {
      // display: flex;
      // align-items: center;

      .form-content {
        display: flex;
        flex-shrink: 0;
        // align-items: center;
        width: 50%;
        padding-right: 85px;

        .content-text {
          font-size: 14px;
          line-height: 36px;
          color: rgba(0, 0, 0, 0.65);
        }

        .content-list {
          font-size: 14px;
          line-height: 36px;
          color: rgba(0, 0, 0, 0.65);
        }

        .content-list + .content-list {
          margin-top: -8px;
        }

        .content-btn {
          display: flex;
          margin-left: 58px;
          .btn-tiem {
            font-size: 14px;
            cursor: pointer;
          }
          .btn-tiem + .btn-tiem {
            margin-left: 20px;
          }
        }
      }

      .form-btn {
        display: flex;
        padding-right: 108px;
        align-items: center;
        .btn-item + .btn-item {
          margin-left: 20px;
        }
        .el-icon-question {
          color: #3479ef;
          font-size: 18px;
        }
      }

      .form-tip {
        display: flex;
        flex-shrink: 0;
        // align-items: center;
        width: 440px;
        font-size: 12px;
        color: #606266;

        .el-icon-info {
          display: inline-block;
          font-size: 16px;
          margin-right: 4px;
          color: #c0c4cc;
          line-height: 36px;
        }
      }
    }
  }
}
</style>